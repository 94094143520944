<template>
    <div class="octave-band-container">
        <div v-if="title" class="octave-band-data-title mt-2">{{ title }}</div>
        <div class="octave-band-range mt-2">
            <div class="octave-band" v-for="(octave, index) in octaves" :key="index">
                <div class="octave-title">{{ octave }}</div>
                <div class="octave-value mt-1">
                    {{ Math.round(powerLevelKit["octaveBand" + octave + "Hz"]) }}
                </div>
            </div>
            <div v-if="lw !== null" class="octave-band">
                <div class="octave-title">{{ lwTitle ? lwTitle : 'Lw' }}</div>
                <div class="octave-value mt-1">{{ lw }}</div>
            </div>
            <div v-if="lwa !== null" class="octave-band">
                <div class="octave-title">{{ lwaTitle ? lwaTitle : filterCorrectionUnit }}</div>
                <div class="octave-value mt-1">{{ lwa }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "OctaveBandContainer",
    props: {
        title: {
            type: String,
            default: () => "",
        },
        powerLevelKit: {
            type: Object,
            default: () => ({
                octaveBand63Hz: 0,
                octaveBand125Hz: 0,
                octaveBand250Hz: 0,
                octaveBand500Hz: 0,
                octaveBand1000Hz: 0,
                octaveBand2000Hz: 0,
                octaveBand4000Hz: 0,
                octaveBand8000Hz: 0,
            }),
        },
        lw: {
            type: [Number, String],
            default: () => null,
        },
        lwTitle: {
            type: String,
            default: '',
        },
        lwa: {
            type: [Number, String],
            default: () => null,
        },
        lwaTitle: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            octaves: [63, 125, 250, 500, 1000, 2000, 4000, 8000],
        };
    },
    computed: {
        ...mapGetters('activeSystemModule', ['filterCorrectionUnit']),
    },
};
</script>

<style scoped>
.octave-band-container {
    display: flex;
    flex-direction: column;
}

.octave-band-range {
    width: 100%;
    flex-direction: row;
    display: flex;
}

.octave-band {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 10%;
}

.octave-band-data-title {
    font-weight: bold;
    color: var(--neutral-color-black-light);
    font-size: 14px;
}

.octave-title {
    color: var(--neutral-color-black-light);
    font-size: 12px;
}

.octave-value {
    font-weight: bold;
    color: var(--neutral-color-black-light);
    font-size: 11px;
}
</style>
